import styled from 'styled-components';

export const Container = styled.main``;

export const ToggleViewButton = styled.button`
  border: none;
  background: var(--blue);
  border-radius: 10px;
  padding: 5px;

  font-weight: 500;
  color: #fff;

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

export const SectionsWrapper = styled.div`
  margin-top: 20px;
  padding: 5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);

  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(300, 1fr));
`;

export const SectionCard = styled.section`
  display: flex;
  flex-direction: column;
  gap: 5px;

  border-radius: 10px;
  background-color: #fff;
  padding: 5px;
  color: #333333aa;
`;

export const SectionCardHeader = styled.header`
  border-radius: 10px;
  background-color: var(--green);
  padding: 5px;

  h2 {
    color: var(--blue);
    font-weight: 500;
  }
`;

export const QuestionnairesWrapper = styled.div`
  display: flex;
  gap: 5px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const QuestionnaireCard = styled.div`
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 5px;
  cursor: pointer;

  display: flex;
  flex: 1;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  h3 {
    text-align: center;
  }
`;

export const QuestionnaireName = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  img {
    width: 30px;
    height: 30px;
  }
`;
