import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 40px 40px;

  @media (max-width: 700px) {
    padding: 5px;
  }
`;

export const NotificationsWrapper = styled.main`
  border-radius: 10px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.05);

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 5px;

  @media (max-width: 700px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  @media (max-width: 350px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

export const NotificationItem = styled.div`
  border-radius: 10px;
  padding: 5px;
  background-color: #fff;
`;

export const NotificationHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2px;
`;

export const HeaderTopic = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  padding: 3px;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid var(--blue);
  height: fit-content;
`;

export const NotificationBody = styled.p`
  margin-top: 2px;
  border-radius: 8px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.02);

  text-align: justify;

  border: 1px solid var(--blue);
`;
