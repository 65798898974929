import styled from 'styled-components';

export const Container = styled.main`
  padding: 0 40px 40px;
`;

export const AverageScoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 0 auto 10px;
  padding: 3px;

  h1 {
    @media (max-width: 700px) {
      font-size: 18px;
    }
  }

  img {
    width: 40px;
    height: 40px;
  }
`;

export const Completion = styled.div`
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 10px auto;
  padding: 3px;
  text-align: center;

  h1 {
    margin: 0;

    @media (max-width: 700px) {
      font-size: 18px;
    }
  }

  p {
    font-size: 18px;
  }
`;

export const CategoriesScoresWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  gap: 5px;

  border-radius: 10px;
  margin: 0 auto 50px;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  a,
  section {
    text-decoration: none;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 10px;
    width: 100%;

    svg {
      color: var(--blue);
      font-size: 40px;
      margin: 7px 0;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;

      font-weight: 500;
      font-size: 18px;
    }

    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }
`;

export const BlockData = styled.h2`
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 5px;
`;
