import { useState, useEffect, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';
import { FormControlLabel, MenuItem, Select, Switch } from '@mui/material';
import {
  Check,
  Close,
  Visibility,
  VisibilityOff,
  SendOutlined,
  Delete,
  Loyalty,
  PaidOutlined
} from '@mui/icons-material';
import lodash from 'lodash';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import { useOrganisation } from '../../../hooks/organisations';

import {
  toggleUserActivation,
  toggleUserSubscriptionFlag,
  updateUserDivision,
  updateUserRole
} from '../../../functions/users';
import {
  addOrganisationManager,
  removeOrganisationManager
} from '../../../functions/organisations';
import { firebaseFunctions, isProduction } from '../../../services/firebase';
import { makeHashPassword } from '../../../utils/makeHashPassword';
import { formatDate } from '../../../utils/formatDate';
import { User } from '../../../models/user';

import { Spinner } from '../../../components/Spinner';
import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';
import { OrganisationFilter } from '../../../components/OrganisationFilter';

import {
  ActivationButton,
  Container,
  CreatedAt,
  DivisionName,
  DivisionRoleColumn,
  InfoWrapper,
  MainData,
  ToggleDivisions,
  ToggleViewWrapper,
  UserCard,
  UserIndex,
  UserName,
  UsersWrapper,
  KyndAdminOptions
} from './styles';

interface HandleViewUsersProps {
  category: 'OrgAdmins' | 'Clinicians' | 'Users' | 'KyndAdmins';
  status: boolean;
}

export const Users: React.FC = () => {
  const [usersView, setUsersView] = useState([] as User[]);
  const [endUsers, setEndUsers] = useState<User[]>([]);
  const [clinicians, setClinicians] = useState<User[]>([]);
  const [kyndAdmins, setKyndAdmins] = useState<User[]>([]);
  const [orgAdmins, setOrgAdmins] = useState<User[]>([]);
  const [isShowingUsersDivisions, setIsShowingUsersDivisions] = useState(false);
  const [isChangingUserDivision, setIsChangingUserDivision] = useState(false);
  const [isChangingUserRole, setIsChangingUserRole] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);

  const [isChangingSubscriptionStatus, setIsChangingSubscriptionStatus] =
    useState(false);
  const [isChangingUserActivation, setIsChangingUserActivation] =
    useState(false);

  const [isViewingOrgAdmins, setIsViewingOrgAdmins] = useState(true);
  const [isViewingKyndAdmins, setIsViewingKyndAdmins] = useState(true);
  const [isViewingClinicians, setIsViewingClinicians] = useState(true);
  const [isViewingEndUsers, setIsViewingEndUsers] = useState(true);

  const { user } = useAuth();
  const { addToast } = useToast();
  const {
    currentOrganisation,
    chosenOrganisation,
    users,
    setUsers,
    isLoadingUsers
  } = useOrganisation();

  const isSuperUser = useMemo(() => {
    if (!user) return false;

    const superAdminEmails = [
      'livi@kyndwellness.com',
      'tom@drtomonamission.com',
      'tania@kyndwellness.com',
      'dee@kyndwellness.com',
      'jo.davies@kyndwellness.com'
    ];

    return !!(
      superAdminEmails.includes(user.email || '') || user.role === 'Kynd Admin'
    );
  }, [user]);

  useEffect(() => {
    const kyndAdminsArray = users.filter(
      (_user) => _user.role === 'Kynd Admin'
    );
    const cliniciansArray = users.filter((_user) => _user.role === 'Clinician');
    const orgAdminsArray = users.filter((_user) => _user.role === 'Org Admin');
    const usersArray = users.filter(
      (_user) => !_user.role || _user.role === 'User'
    );

    setKyndAdmins(kyndAdminsArray);
    setClinicians(cliniciansArray);
    setOrgAdmins(orgAdminsArray);
    setEndUsers(usersArray);
    setUsersView([
      ...kyndAdminsArray,
      ...orgAdminsArray,
      ...cliniciansArray,
      ...usersArray
    ]);

    setIsViewingKyndAdmins(true);
    setIsViewingOrgAdmins(true);
    setIsViewingClinicians(true);
    setIsViewingEndUsers(true);
  }, [addToast, user?.role, users]);

  const handleViewUsers = useCallback(
    ({ category, status }: HandleViewUsersProps) => {
      let filteredUsers: User[] = [];

      switch (category) {
        case 'OrgAdmins':
          filteredUsers = status
            ? [...usersView, ...orgAdmins]
            : usersView.filter((_user) => _user.role !== 'Org Admin');
          setUsersView(filteredUsers);
          setIsViewingOrgAdmins(status);
          break;
        case 'Clinicians':
          filteredUsers = status
            ? [...usersView, ...clinicians]
            : usersView.filter((_user) => _user.role !== 'Clinician');
          setUsersView(filteredUsers);
          setIsViewingClinicians(status);
          break;
        case 'KyndAdmins':
          filteredUsers = status
            ? [...usersView, ...kyndAdmins]
            : usersView.filter((_user) => _user.role !== 'Kynd Admin');
          setUsersView(filteredUsers);
          setIsViewingKyndAdmins(status);
          break;
        default:
          filteredUsers = status
            ? [...usersView, ...endUsers]
            : usersView.filter((_user) => _user.role !== 'User');
          setUsersView(filteredUsers);
          setIsViewingEndUsers(status);
          break;
      }
    },
    [usersView, orgAdmins, clinicians, endUsers, kyndAdmins]
  );

  const handleChangeUserDivision = useCallback(
    async (_user: User, divisionId: string) => {
      setIsChangingUserDivision(true);
      const division = currentOrganisation.divisions.find(
        (div) => div.id === divisionId
      );

      if (division) {
        const { id, name } = division;

        const userDivision = {
          id,
          name
        };

        try {
          await updateUserDivision(_user, userDivision);

          const currentUsersCopy = [...users];

          const userIndex = currentUsersCopy.findIndex(
            (usr) => usr.id === _user.id
          );

          if (userIndex >= 0) {
            currentUsersCopy[userIndex].division = userDivision;
            setUsers(currentUsersCopy);
          }
          setIsChangingUserDivision(false);

          addToast({
            title: 'User division updated',
            type: 'success'
          });
        } catch (error) {
          console.log(error);
          setIsChangingUserDivision(false);

          addToast({
            title: 'Error updating user division',
            description: 'Please, try again later',
            type: 'error'
          });
        }
      }
    },
    [currentOrganisation, addToast, users, setUsers]
  );

  const handleChangeUserRole = useCallback(
    async (_user: User, role: string) => {
      if (_user.role === role) return;

      const confirmChange = window.confirm(
        `Are you sure you want to switch "${_user.firstName} ${_user.lastName}" from "${_user.role}" to "${role}"?`
      );

      if (!confirmChange) return;

      setIsChangingUserRole(true);

      try {
        await updateUserRole(_user.id!, role);

        if (role === 'Org Admin') {
          const manager = {
            id: _user.id!,
            name: `${_user.firstName} ${_user.lastName}`
          };

          await addOrganisationManager(currentOrganisation.id, manager);
        }

        if (_user.role === 'Org Admin') {
          const manager = {
            id: _user.id!,
            name: `${_user.firstName} ${_user.lastName}`
          };

          await removeOrganisationManager(currentOrganisation.id, manager);
        }

        const currentUsersCopy = [...users];

        const userIndex = currentUsersCopy.findIndex(
          (usr) => usr.id === _user.id
        );

        if (userIndex >= 0) {
          currentUsersCopy[userIndex].role = role;
          setUsers(currentUsersCopy);
        }
        setIsChangingUserRole(false);

        addToast({
          title: 'User role updated',
          type: 'success'
        });
      } catch (error) {
        console.log(error);
        setIsChangingUserRole(false);

        addToast({
          title: 'Error updating user role',
          description: 'Please, try again later',
          type: 'error'
        });
      }
    },
    [addToast, users, currentOrganisation.id, setUsers]
  );

  const handleDeleteUser = useCallback(
    async (userId: string, firstName: string) => {
      const confirmDeleteUser = window.confirm(
        `Are you sure you want to permanently delete "${firstName}"? This action is irreversible.`
      );

      if (!confirmDeleteUser) return;

      setIsDeletingUser(true);

      try {
        const deleteUserPersonalData = firebaseFunctions.httpsCallable(
          'deleteUserPersonalData'
        );

        const hasDeletedUser = await deleteUserPersonalData(userId);

        if (hasDeletedUser) {
          const currentUsersCopy = lodash.cloneDeep(users);
          const userIndexDeleted = currentUsersCopy.findIndex(
            (usr) => usr.id === userId
          );

          currentUsersCopy.splice(userIndexDeleted, 1);

          setUsers(currentUsersCopy);
        }

        setIsDeletingUser(false);

        addToast({
          title: 'User deleted',
          type: 'success'
        });
      } catch (error) {
        setIsDeletingUser(false);
        console.log(error);

        addToast({
          title: 'An error has occured while deleting user',
          description: 'Please try again later',
          type: 'error'
        });

        if (isProduction) {
          Sentry.captureException(error);
        }
      }
    },
    [setUsers, users, addToast]
  );

  const handleResetPassword = useCallback(
    async (userId: string, email: string) => {
      const newPassword = makeHashPassword();

      const confirmResetPassword = window.confirm(
        `Generate new password to ${email}? The new password will be:    ${newPassword}`
      );

      if (!confirmResetPassword) return;

      setIsChangingPassword(true);

      const setNewPassword = firebaseFunctions.httpsCallable('setNewPassword');

      try {
        const passwordSet = await setNewPassword({
          userId,
          password: newPassword
        });

        if (passwordSet) {
          addToast({
            title: `New password set successfully for ${email}`,
            description: `${newPassword}`,
            duration: 20000,
            type: 'success'
          });
        } else {
          addToast({
            title: 'Error updating password',
            description:
              'User is registered with a phone number, which does not require a password',
            type: 'info'
          });
        }
      } catch (error) {
        console.log(error);
        setIsChangingPassword(false);

        addToast({
          title: 'Error updating password',
          description: 'Please, try again later',
          type: 'error'
        });
      }

      setIsChangingPassword(false);
    },
    [addToast]
  );

  const handleUserActivation = useCallback(
    async (_user: User) => {
      setIsChangingUserActivation(true);
      const status = !_user.isActive;

      let confirmRemove = false;

      if (!status && chosenOrganisation !== 'Subscriptions') {
        confirmRemove = window.confirm(
          `${_user.firstName} will be inactivated. Do you also want to REMOVE them from their organisation?`
        );
      }

      try {
        await toggleUserActivation(_user, status, confirmRemove);

        const currentUsersCopy = lodash.cloneDeep(users);
        const userIndex = currentUsersCopy.findIndex(
          (usr) => usr.id === _user.id
        );

        if (confirmRemove) {
          currentUsersCopy.splice(userIndex, 1);
        } else {
          currentUsersCopy[userIndex].isActive = status;
          currentUsersCopy[userIndex].isSubscriptionUser = !status;
        }

        setUsers(currentUsersCopy);
        setIsChangingUserActivation(false);
      } catch (error) {
        console.log(error);
        setIsChangingUserActivation(false);

        addToast({
          title: 'Error changing activation',
          description: 'Please try again later',
          type: 'error'
        });
      }
    },
    [users, addToast, setUsers, chosenOrganisation]
  );

  const toggleShowUsersDivisions = useCallback(() => {
    setIsShowingUsersDivisions(!isShowingUsersDivisions);
  }, [isShowingUsersDivisions]);

  const roles = useMemo(() => {
    const rolesArray = ['User', 'Org Admin'];

    if (!user) return rolesArray;

    if (user.role === 'Kynd Admin') {
      rolesArray.push(...['Clinician', 'Kynd Admin']);
    }

    return rolesArray;
  }, [user]);

  const getUserRoleColor = useCallback((role: string) => {
    switch (role) {
      case 'Org Admin':
        return '#0d3f52';
      case 'Clinician':
        return '#70d4ff';
      case 'Kynd Admin':
        return '#ff5e7b';
      default:
        return '#f2f2f2';
    }
  }, []);

  const handleToggleUserSubscription = useCallback(
    async (
      userId: string,
      currentSubscriptionStatus: boolean,
      userName: string
    ) => {
      try {
        const confirmToggle = window.confirm(
          `Are you sure you want to toggle ${userName}'s subscription status? If yes, they will now ${
            currentSubscriptionStatus
              ? 'be able to use their app for free'
              : 'have to pay to use their app'
          }`
        );

        if (!confirmToggle) return;
        setIsChangingSubscriptionStatus(true);
        await toggleUserSubscriptionFlag(userId, !currentSubscriptionStatus);

        const currentUsersCopy = lodash.cloneDeep(users);

        const userIndex = currentUsersCopy.findIndex(
          (usr) => usr.id === userId
        );

        if (userIndex >= 0) {
          currentUsersCopy[userIndex].isSubscriptionUser =
            !currentSubscriptionStatus;
          setUsers(currentUsersCopy);
        }

        setIsChangingSubscriptionStatus(false);
        addToast({
          title: `${userName} will now ${
            currentSubscriptionStatus
              ? 'be able to use the app for free'
              : 'have to pay to use the app'
          }`,
          type: 'success'
        });
      } catch (err) {
        console.log(err);

        setIsChangingSubscriptionStatus(false);
        addToast({
          title: `Error toggling ${userName}'s subscription`,
          description: 'If it persists, contact support',
          type: 'error'
        });
      }
    },
    [addToast, setUsers, users]
  );

  return (
    <>
      <OrganisationFilter hideDivision />
      <Container>
        <Helmet>
          <title>
            {user?.role === 'Kynd Admin' ? 'Administrators' : 'Users'}
          </title>
        </Helmet>

        {chosenOrganisation !== 'All' && (
          <ToggleDivisions
            type="button"
            onClick={toggleShowUsersDivisions}
            title={
              isShowingUsersDivisions
                ? 'Click to hide divisions and roles'
                : 'Click to show divisions and roles'
            }
          >
            {isShowingUsersDivisions ? <Visibility /> : <VisibilityOff />}
          </ToggleDivisions>
        )}

        <PageTitleWrapper>
          <PageTitle heading="Administrators and Users" />

          <ToggleViewWrapper>
            {isSuperUser && (
              <FormControlLabel
                sx={{ color: 'text.primary' }}
                control={
                  <Switch
                    checked={isViewingKyndAdmins}
                    color="error"
                    onChange={() =>
                      handleViewUsers({
                        category: 'KyndAdmins',
                        status: !isViewingKyndAdmins
                      })
                    }
                  />
                }
                label="Kynd Admins"
              />
            )}

            <FormControlLabel
              sx={{ color: 'text.primary' }}
              control={
                <Switch
                  checked={isViewingOrgAdmins}
                  onChange={() =>
                    handleViewUsers({
                      category: 'OrgAdmins',
                      status: !isViewingOrgAdmins
                    })
                  }
                  style={{ color: 'var(--blue)' }}
                />
              }
              label="Org Admins"
            />

            <FormControlLabel
              sx={{ color: 'text.primary' }}
              control={
                <Switch
                  checked={isViewingClinicians}
                  color="info"
                  onChange={() =>
                    handleViewUsers({
                      category: 'Clinicians',
                      status: !isViewingClinicians
                    })
                  }
                />
              }
              label="Clinicians"
            />

            <FormControlLabel
              sx={{ color: 'text.primary' }}
              control={
                <Switch
                  checked={isViewingEndUsers}
                  color="secondary"
                  onChange={() =>
                    handleViewUsers({
                      category: 'Users',
                      status: !isViewingEndUsers
                    })
                  }
                />
              }
              label="Users"
            />
          </ToggleViewWrapper>
        </PageTitleWrapper>

        {isDeletingUser && <Spinner message="Deleting User" />}
        {isChangingPassword && <Spinner message="Changing password" />}
        {isLoadingUsers && <Spinner message="Loading users" />}
        {isChangingUserActivation && (
          <Spinner message="Changing user activation" />
        )}
        {isChangingSubscriptionStatus && (
          <Spinner message="Changing user subscription status" />
        )}

        {!users.length && (
          <h2>No users found. Type in the search bar to find users</h2>
        )}

        {!!users.length && <h3>{users.length} users found</h3>}

        <UsersWrapper>
          {usersView.map((_user, userIndex) => (
            <UserCard>
              <UserIndex background={getUserRoleColor(_user.role!)}>
                {userIndex + 1}

                {user?.role === 'Kynd Admin' && (
                  <KyndAdminOptions>
                    {!_user.isActive && (
                      <button
                        type="button"
                        onClick={() =>
                          handleDeleteUser(_user.id!, _user.firstName!)
                        }
                        title="Permanently delete user"
                      >
                        <Delete className="deleteUser" />
                      </button>
                    )}
                    {_user.email && (
                      <button
                        type="button"
                        onClick={() =>
                          handleResetPassword(_user.id!, _user.email!)
                        }
                        title="Generate and set new password"
                      >
                        <SendOutlined className="newPassword" />
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() =>
                        handleToggleUserSubscription(
                          _user.id!,
                          Boolean(_user.isSubscriptionUser),
                          _user.firstName
                        )
                      }
                      title={
                        _user.isSubscriptionUser
                          ? 'SUBSCRIPTION USER: Switch user from the subscription mode to the organisation mode, where they do not have to pay to user the app'
                          : 'NON SUBSCRIPTION USER: Turn user into a subscription user, so they will be requested to pay for the app'
                      }
                    >
                      {_user.isSubscriptionUser ? (
                        <PaidOutlined />
                      ) : (
                        <Loyalty />
                      )}
                    </button>
                  </KyndAdminOptions>
                )}
              </UserIndex>

              <InfoWrapper>
                <MainData>
                  <UserName>{`${_user.firstName} ${_user?.lastName}`}</UserName>

                  <ActivationButton
                    type="button"
                    title={
                      _user.isActive
                        ? 'Active user. Click to make them inactivate'
                        : 'Inactive user. Click to make them active again'
                    }
                    onClick={() => handleUserActivation({ ..._user })}
                  >
                    {_user.isActive ? (
                      <Check color="success" />
                    ) : (
                      <Close color="error" />
                    )}
                  </ActivationButton>
                </MainData>

                {user?.role === 'Kynd Admin' && (
                  <>
                    <CreatedAt>
                      <p>Contact</p>
                      <strong>{_user.phone || _user.email}</strong>
                    </CreatedAt>

                    <CreatedAt>
                      <p>Created at</p>
                      <strong>
                        {formatDate({ date: _user.createdAt as any })}
                      </strong>
                    </CreatedAt>

                    <CreatedAt>
                      <p>Updated at</p>
                      <strong>
                        {formatDate({ date: _user.updatedAt as any })}
                      </strong>
                    </CreatedAt>
                  </>
                )}

                {chosenOrganisation === 'All' && (
                  <CreatedAt>
                    <p>Organisation</p>
                    <strong>{_user.organisation.name}</strong>
                  </CreatedAt>
                )}

                {isShowingUsersDivisions && chosenOrganisation !== 'All' && (
                  <DivisionRoleColumn>
                    <Select
                      disabled={isChangingUserDivision}
                      style={{ width: '100%' }}
                      value={_user.division.id}
                      onChange={(e) =>
                        handleChangeUserDivision(_user, e.target.value)
                      }
                    >
                      {currentOrganisation.divisions.map((division) => (
                        <MenuItem key={division.id} value={division.id}>
                          <DivisionName>{division.name}</DivisionName>
                        </MenuItem>
                      ))}
                    </Select>

                    <Select
                      disabled={isChangingUserRole}
                      style={{ width: '100%' }}
                      value={_user.role}
                      onChange={(e) =>
                        handleChangeUserRole(_user, e.target.value)
                      }
                    >
                      {roles.map((role) => (
                        <MenuItem key={role} value={role}>
                          <DivisionName>{role}</DivisionName>
                        </MenuItem>
                      ))}
                    </Select>
                  </DivisionRoleColumn>
                )}
              </InfoWrapper>
            </UserCard>
          ))}
        </UsersWrapper>
      </Container>
    </>
  );
};
