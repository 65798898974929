import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --main-color: #0d3f52;
    --secondary-color: #72D3EA;
    --background-color: #ECF8F7;
    --text-color: #333333aa;
    --white: #fff;
    --blue: #0d3f52;
    --green: #cbd853;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: var(--text-color);
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
    color: var(--text-color);
  }

  p {
    color: #333333aa;
  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }
`;
