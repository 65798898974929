import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;
  position: relative;

  @media (max-width: 700px) {
    padding: 10px;
  }
`;

export const UserCardsWrapper = styled.main`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 16px;

  margin-top: 16px;

  &.new-check-button {
    color: black !important;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 8px;
  }

  @media (max-width: 350px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 3px;
  }
`;

export const UserName = styled.h2`
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333cc;

  div {
    display: flex;

    p {
      margin: 0 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  p {
    margin: 0 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: 0;

    background: transparent;
    border: none;
    border-radius: 5px;
    height: 100%;
    width: 60px;

    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    svg {
      pointer-events: none;
    }
  }
`;

export const UserData = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const UserDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    margin-bottom: 0;
  }
`;

export const AlertWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6px;
  height: 100%;
`;

export const AlertCard = styled.div`
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px;

  height: 100%;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  transition: transform 0.1s, background-color 0.1s;

  &.average {
    flex-direction: column;
    justify-content: center;
  }

  &:hover {
    transform: scale(1.01);
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

export const TopLine = styled.header`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  img {
    height: 25px;
    width: 30px;
    opacity: 0.5;
  }

  p {
    text-align: center;
    margin: 0;
    min-width: 65px;
  }
`;

export const DataRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  color: #333333;
  max-width: 100%;
  border-radius: 5px;
  padding: 0 6px;
  background-color: rgba(0, 0, 0, 0.05);
  height: 31px;

  transition: background-color 0.1s;

  @media (max-width: 600px) {
    margin: 2px 0;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  strong {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333333aa;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const ButtonsRow = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  @media (max-width: 600px) {
    margin-top: 10px;
    flex-direction: column;
  }
`;

export const FooterButton = styled.button`
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  color: var(--blue);
  border: solid 1px var(--blue);
  height: 30px;
  padding: 0 10px;
  width: 100%;

  font-size: 16px;
  font-weight: 500;

  transition: background-color 0.2s;
  &:hover {
    background-color: var(--green);
  }
`;

export const DatesRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  font-size: 10px;
  color: #333;
  margin-top: 5px;
`;

interface ColorLabelProps {
  backgroundColor: string;
}

export const ColorLabel = styled.span<ColorLabelProps>`
  background-color: ${(props) => props.backgroundColor};
  margin: 5px 0;
  height: 15px;
  width: 15px;
  min-height: 15px;
  min-width: 15px;
  max-height: 15px;
  max-width: 15px;
  border-radius: 50%;
`;
