import { useMemo } from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container } from '@mui/material';
import { PhoneIphoneOutlined } from '@mui/icons-material';

import { useAuth } from '../../hooks/auth';
import { useOrganisation } from '../../hooks/organisations';

import { OrganisationFilter } from '../../components/OrganisationFilter';
import { AverageChart } from './components/AverageChart';

import {
  AverageScoreWrapper,
  BlockData,
  CategoriesScoresWrapper,
  Completion
} from './styles';

import iconBody from '../../assets/icons/body-icon.png';
import iconLife from '../../assets/icons/life-icon.png';
import iconMind from '../../assets/icons/mind-icon.png';
import kyndIcon from '../../assets/icons/logo-small-filled.png';
import { getAverageChartData } from '../../utils/getAverageChartData';
import { OrganisationAverageScore } from '../../models/organisation';

interface CategoriesArrayProps {
  camelCase: 'body' | 'mind' | 'life';
  upperCase: 'Body' | 'Mind' | 'Life';
}

export const Dashboard: React.FC = () => {
  const {
    orgIndustryComparison,
    currentOrganisation,
    chosenDivisionId,
    isComparingOrganisations,
    industryToCompare,
    MINIMUM_USERS_TO_SEE_STATISTICS
  } = useOrganisation();
  const { user } = useAuth();

  const categoryIcons = useMemo(() => {
    return {
      body: iconBody,
      mind: iconMind,
      life: iconLife,
      kynd: kyndIcon
    } as Record<string, string>;
  }, []);

  const categories = useMemo(() => {
    return [
      {
        camelCase: 'body',
        upperCase: 'Body'
      },
      {
        camelCase: 'mind',
        upperCase: 'Mind'
      },
      {
        camelCase: 'life',
        upperCase: 'Life'
      }
    ] as CategoriesArrayProps[];
  }, []);

  const { averageScore, totalLights } = useMemo(() => {
    return getAverageChartData(currentOrganisation, chosenDivisionId);
  }, [currentOrganisation, chosenDivisionId]);

  const orgComparisonData = useMemo(() => {
    return getAverageChartData(orgIndustryComparison, 'all');
  }, [orgIndustryComparison]);

  const totalEmployees = useMemo(() => {
    if (averageScore && averageScore.averageScore) {
      return averageScore.activeUsers || 0;
    }

    return 0;
  }, [averageScore]);

  const comparisonTotalEmployees = useMemo(() => {
    return get(orgComparisonData, 'averageScore.activeUsers', 0);
  }, [orgComparisonData]);

  const comparisonOrgAverageScore = get(
    orgComparisonData,
    'averageScore',
    {} as OrganisationAverageScore
  );

  return (
    <>
      <OrganisationFilter hideUserSearch showOrgsComparison />

      <Helmet>
        <title>Summary</title>
      </Helmet>

      {averageScore.activeUsers > MINIMUM_USERS_TO_SEE_STATISTICS ||
      user?.role === 'Kynd Admin' ? (
        <Container>
          <AverageScoreWrapper>
            <img src={categoryIcons.kynd} alt="KYND Total Score" />
            <h1>KYND Total Score:</h1>
            <h1>{averageScore.averageScore || '-'}</h1>
          </AverageScoreWrapper>

          {isComparingOrganisations && (
            <Completion>
              <h1>My organisation&apos;s average</h1>
            </Completion>
          )}
          <AverageChart data={averageScore} totalLights={totalLights} />

          {isComparingOrganisations && (
            <>
              <Completion>
                <h1>{`${industryToCompare} average`}</h1>
              </Completion>

              <AverageChart
                data={orgComparisonData.averageScore}
                totalLights={orgComparisonData.totalLights}
              />
            </>
          )}

          <Completion>
            <h1>Completion summary</h1>
          </Completion>

          <CategoriesScoresWrapper>
            <section>
              <h2>#Users</h2>

              <div>
                <PhoneIphoneOutlined />
                <h1>{totalEmployees}</h1>
              </div>

              <h6>Active users</h6>
            </section>

            {categories.map(({ camelCase, upperCase }) => {
              return (
                <Link
                  to={`/dashboard/${camelCase}`}
                  title={`Go to ${upperCase} statistics`}
                  key={camelCase}
                >
                  <h2>{upperCase}</h2>

                  <div>
                    <img
                      alt={`${camelCase} icon`}
                      src={categoryIcons[camelCase]}
                    />
                    <h1>{averageScore[`${camelCase}Complete`] || '-'}</h1>
                  </div>

                  <h6>Users completed</h6>
                </Link>
              );
            })}
          </CategoriesScoresWrapper>

          {isComparingOrganisations && (
            <>
              <Completion>
                <h1>{`${industryToCompare} Completion summary`}</h1>
              </Completion>

              <CategoriesScoresWrapper>
                <section>
                  <h2>#Users</h2>

                  <div>
                    <PhoneIphoneOutlined />
                    <h1>{comparisonTotalEmployees}</h1>
                  </div>

                  <h6>Active users</h6>
                </section>

                {categories.map(({ camelCase, upperCase }) => {
                  return (
                    <Link
                      to={`/dashboard/${camelCase}`}
                      title={`Go to ${upperCase} statistics`}
                      key={camelCase}
                    >
                      <h2>{upperCase}</h2>

                      <div>
                        <img
                          alt={`${camelCase} icon`}
                          src={categoryIcons[camelCase]}
                        />
                        <h1>
                          {comparisonOrgAverageScore[`${camelCase}Complete`] ||
                            '-'}
                        </h1>
                      </div>

                      <h6>Users completed</h6>
                    </Link>
                  );
                })}
              </CategoriesScoresWrapper>
            </>
          )}
        </Container>
      ) : (
        <Container>
          <BlockData>
            You will be able to see statistics here when we gather data from at
            least {MINIMUM_USERS_TO_SEE_STATISTICS} users
          </BlockData>
        </Container>
      )}
    </>
  );
};
